<ng-container *ngIf="!hasError; else errorTemplate">
  <div class="main-content-container" *ngIf="appointment$ | async as appointment">
    <div class="main-content-wrapper">
      <div class="header">
        <i class="icon-check"></i>
        <span class="title">Ви успішно записалися!</span>
        <span class="subtitle">Онлайн-запис є рівнозначним запису за телефоном і не потребує підтвердження</span>
      </div>

      <div class="content-container">
        <div class="content">
          <div class="top-info">
            <div class="date-time">
              <span class="date">{{appointment.start_at | date: 'dd MMMM, EEEE'}}</span>
              <span class="time">{{appointmentStartTime}} - {{appointmentEndTime}}</span>
            </div>

            <div class="employee">
              <app-avatar
                [firstName]="appointment.employee?.first_name"
                [lastName]="appointment.employee?.last_name"
                [image]="appointment.employee?.avatar"
                size="xs"
              ></app-avatar>
              <span>{{appointment.employee?.first_name}} {{appointment.employee?.last_name}}</span>
            </div>
          </div>

          <ul class="service-list">
            <li *ngFor="let service of appointment.services" class="service-list__details">
              <span class="title">{{service.title}}</span>
              <div class="info">
                <span class="duration">
                  {{ service.duration | duration }}
                </span>
                <span class="price">
                  {{ service.price }} грн
                </span>
              </div>
            </li>
          </ul>

          <span class="summary">Всього: {{appointmentTotalPrice}} грн</span>
        </div>

        <div class="action-block">
          <div class="action-block__actions">
            <button class="outline" (click)="addToGoogleCalendar()">
              <i class="icon-calendar-dates"></i>
              Додати в календар
            </button>
            <button class="outline" (click)="openTelegramBot()">
              <i class="icon-telegram"></i>
              Сповіщати в Telegram
            </button>
          </div>
          <button class="primary-dark" (click)="goToHomePage()">
            <i class="icon-refresh"></i>
            Записатись знову
          </button>
        </div>
      </div>
    </div>

    <footer>
      <span>Онлайн запис надано сервісом</span>
      <a href="https://natodi.com/" target="_blank">
        <div class="logo">
          <i class="icon-logo"></i>
          <i class="icon-logo-text"></i>
        </div>
      </a>
    </footer>
  </div>
</ng-container>

<ng-template #errorTemplate>
  <app-error-page
    [companyId]="branch.company_id"
    [branchId]="branch.id"
    [errorCode]="errorCode"
    (onClickButton)="goToHomePage()"
  ></app-error-page>
</ng-template>
