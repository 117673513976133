import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './components/home/home.component'
import { EmployeeComponent } from './components/employee/employee.component'
import { ServicesComponent } from './components/services/services.component'
import { DateComponent } from './components/date/date.component'
import { SuccessComponent } from './components/success/success.component'
import { ConfirmationComponent } from './components/confirmation/confirmation.component'
import { AppComponent } from './app.component'
import { Path } from './app-routing.config'

const routes: Routes = [
  {
    path: ':branchSlug',
    component: AppComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: Path.EMPLOYEE,
        component: EmployeeComponent,
      },
      {
        path: Path.SERVICES,
        component: ServicesComponent,
      },
      {
        path: Path.DATE,
        component: DateComponent,
      },
      {
        path: Path.CONFIRMATION,
        component: ConfirmationComponent,
      },
      {
        path: `${Path.SUCCESS}/:appointmentId`,
        component: SuccessComponent,
      },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
      scrollOffset: [0, 0]
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
