<div class="main-content-container">
  <div class="main-content-wrapper">
    <app-empty-state
      [icon]="errorInfo.icon"
      [title]="errorInfo.title"
      [description]="errorInfo.description"
      buttonTitle="Спробувати ще раз"
      [hasButtonIcon]="false"
      (onClickButton)="onClickButton.emit()"
    >
    </app-empty-state>
  </div>

  <footer>
    <span>Онлайн запис надано сервісом</span>
    <a href="https://natodi.com/" target="_blank">
      <div class="logo">
        <i class="icon-logo"></i>
        <i class="icon-logo-text"></i>
      </div>
    </a>
  </footer>
</div>
