import { Selector } from "@ngxs/store";
import { APP_STATE_TOKEN, IAppState } from './app-state.interface'
import { AppointmentFormModel } from '../interfaces/appointment.interface'
import { Branch } from '../interfaces/branch.interface'
import { BusinessArea, Company } from '../interfaces/company.interface'

export class AppStateSelectors {
  @Selector([APP_STATE_TOKEN])
  static appointmentFormModel(state: IAppState): AppointmentFormModel {
    return state.newAppointmentForm.model;
  }

  @Selector([APP_STATE_TOKEN])
  static allBranches(state: IAppState): Branch[] {
    return [...state.branches];
  }

  @Selector([APP_STATE_TOKEN])
  static company(state: IAppState): Company {
    return state.company;
  }

  @Selector([APP_STATE_TOKEN])
  static businessAreas(state: IAppState): BusinessArea[] {
    return state.businessAreas;
  }

  @Selector([APP_STATE_TOKEN])
  static totalNumberOfBranches(state: IAppState): number {
    return state.totalNumberOfBranches;
  }

  @Selector([APP_STATE_TOKEN])
  static activeBranch(state: IAppState): Branch {
    return {...state.activeBranch};
  }
}
