import { IPaginationResponse } from '../entities/app.model'
import { Branch } from './branch.interface'

export interface ICompanyResponse extends IPaginationResponse {
    data: Company;
}

export interface IBusinessAreaResponse {
    data: BusinessArea[];
}

export interface IBranchesResponse extends IPaginationResponse {
    data: Branch[];
}

export interface IGetBranchesParams {
    branchId?: string | number;
    search?: string | null;
    limit?: number;
    offset?: number;
}

export interface BusinessArea {
    id: string;
    title: string;
}

export class Company {
    id: string;
    business_area_id: string;
    title: string;
    logo: string;
    site: string;
    instagram: string;
    facebook: string;
    twitter: string;
    tiktok: string;

    business_area: BusinessArea;
}
