import { Component, OnInit, forwardRef, Injector, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormControlValueAccessorConnector } from '../form-control-value-accessor-connector';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { debounceTime, map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ]
})
export class SearchInputComponent extends FormControlValueAccessorConnector implements OnInit, OnDestroy {
  @Input() placeholder: string = 'Пошук ';
  @Output() onUpdate = new EventEmitter<string>();

  onDestroy$ = new Subject<void>();

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.control.valueChanges.pipe(
      debounceTime(300),
      map(search => {
        this.onUpdate.emit(search);
      }),
      takeUntil(this.onDestroy$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearSearch() {
    this.control.setValue('');
  }

}
