import { StateToken } from '@ngxs/store';
import { AppointmentFormModel } from '../interfaces/appointment.interface'
import { Branch } from '../interfaces/branch.interface'
import { BusinessArea, Company } from '../interfaces/company.interface'


export const APP_STATE_TOKEN = new StateToken<IAppState>('AppState');

export interface IAppState {
  newAppointmentForm: {
    model?: AppointmentFormModel;
  };
  branches: Branch[];
  company: Company;
  businessAreas: BusinessArea[];
  totalNumberOfBranches: number;
  activeBranch: Branch;
}
