export enum Path {
  BRANCHES = `branches`,
  SERVICES = 'services',
  EMPLOYEE = 'employee',
  DATE = 'date',
  CONFIRMATION = 'confirmation',
  SUCCESS = 'success',
  ERROR = 'error',
  NOT_FOUND = '404',
}
