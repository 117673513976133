import { ControlContainer, ControlValueAccessor, FormArray, FormControl, FormControlDirective, NgModel, Validators, AbstractControl } from '@angular/forms';
import { Component, Injector, Input, ViewChild } from '@angular/core';

@Component({
  template: ''
})
export abstract class FormControlValueAccessorConnector implements ControlValueAccessor {

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective | undefined;

  @Input()
  formControl: AbstractControl | undefined;

  @Input()
  formControlName: string | undefined;

  errorMessage: string;

  // @Input()
  // formArrayName: string | undefined;

  protected constructor(private injector: Injector) {}

  get control(): AbstractControl | null {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
    // if (this.formControl) {
    //   return this.formControl;
    // } else if (this.formArrayName) {
    //   // Access the FormArray using formArrayName and then find the control by formControlName
    //   const formArray = this.controlContainer.control.get(this.formArrayName) as FormArray;
    //   console.log(formArray);
    //   return formArray?.at(this.getIndexFromName(this.formControlName)) as FormControl;
    // } else {
    //   return this.controlContainer.control.get(this.formControlName) as FormControl;
    // }
  }

  get required() {
    return this.control?.hasValidator(Validators.required);
  }

  get isPhoneNum() {
    return this.formControlName === 'phone_number';
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  // private getIndexFromName(name: string | undefined): number {
  //   // Assuming the formControlName is set up like 'controlName-index', extract the index
  //   return name ? parseInt(name.split('-').pop() || '0', 10) : 0;
  // }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
  }

  hasError(): boolean {
    if (this.control && this.control.errors) {
      this.errorMessage = this.getFirstErrorMessage();
      const showError = !!this.errorMessage && this.control.dirty;
      return showError;
    }
    return false;
  }

  private getFirstErrorMessage(): string {
    const errors = Object.keys(this.control.errors).sort();
    const firstErrorKey = errors.length && errors[0];
    if (this.control?.errors?.[firstErrorKey]) {
      if (this.isString(this.control.errors[firstErrorKey])) {
        return this.control.errors[firstErrorKey];
      }

      return `forms.errors.${firstErrorKey}`;
    }
    return '';
  }

  private isString(val): val is string {
    return typeof val === 'string';
  }

  getFormValue(): any {
    // Access the whole form group/array value using the control container
    return this.controlContainer.control.value;
  }
}
