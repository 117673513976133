import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { Appointment } from '../../interfaces/appointment.interface'
import { Branch } from '../../interfaces/branch.interface'
import { DatePipe } from '@angular/common'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AppActions } from '../../state/app-state.action'
import { AmplitudeService } from '../../entities/amplitude.service'
import { Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject'
import { format } from 'date-fns'
import { AppointmentsApiService } from '../../api-service/appointments/appointments-api.service'


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;
  appointment$: Observable<Appointment>;

  branch: Branch;
  appointmentStartTime: string;
  appointmentEndTime: string;
  appointmentTotalPrice: number;
  hasError: boolean = false;
  errorCode: number = null;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private appointmentsApiService: AppointmentsApiService,
    private datePipe: DatePipe,
    private amplitudeService: AmplitudeService,
  ) {
  }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.branch = branch;
    });

    this.activatedRoute.paramMap.subscribe(params => {
      if (!params.has('appointmentId')) {
        return;
      }

      const appointmentId = params.get('appointmentId');
      this.getAppointmentData(appointmentId);
    });

    this.resetForm();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getAppointmentData(appointmentId: string) {
    this.appointment$ = this.appointmentsApiService.getAppointmentById(appointmentId);
    this.appointment$.subscribe({
        next: (appointment) => {
          const startAt = new Date(`${appointment.start_at}`);
          const endAt = new Date(`${appointment.end_at}`);
          this.appointmentStartTime = format(startAt, 'HH:mm').split(':').slice(0, 2).join(':');
          this.appointmentEndTime = format(endAt, 'HH:mm').split(':').slice(0, 2).join(':');
          this.appointmentTotalPrice = appointment.price;
        },
        error: (error) => {
          this.hasError = true;
          this.errorCode = 404;
        }
    });
  }

  goToHomePage() {
    this.trackEventOpenHomePage();

    this.router.navigate([this.branch.slug], {
      queryParamsHandling: 'merge',
    });
  }

  addToGoogleCalendar() {
    this.appointment$.subscribe({
      next: (appointment) => {
        this.openGoogleCalendar(appointment);
      }
    });
  }

  openGoogleCalendar(appointment: Appointment) {
    const servicesTitles = appointment.services.map(service => service.title).join(', ')
    const employeeName = `${appointment.employee.first_name} ${appointment.employee.last_name}`
    const title = `${this.branch.title}: ${servicesTitles} до ${employeeName}`

    const description = [
      `Послуги: ${servicesTitles}`,
      `Спеціаліст: ${employeeName}`,
      '',
      'Ми з нетерпінням чекаємо зустрічі з вами!',
      'Дякуємо за вибір Natodi!',
    ].join('\n')

    const [startHours, startMinutes] = this.appointmentStartTime.split(':');
    const [endHours, endMinutes] = this.appointmentEndTime.split(':');

    const googleDate = this.datePipe.transform(appointment.start_at, 'yyyyMMdd');
    const date = `${googleDate}T${startHours}${startMinutes}00/${googleDate}T${endHours}${endMinutes}00`;

    const eventParams = new URLSearchParams({
      action: 'TEMPLATE',
      text: title,
      dates: date,
      details: description,
      location: this.branch.address,
    });

    const baseUrl = 'https://www.google.com/calendar/render';

    this.trackEventAddToGoogleCalendar();
    window.open(baseUrl + '?' + eventParams.toString(), '_blank');
  }

  openTelegramBot() {
    const botUrl = 'https://t.me/natodi_client_bot';
    this.trackEventOpenTelegramBot();
    window.open(botUrl, '_blank');
  }

  resetForm() {
    this.store.dispatch(new AppActions.UpdateAppointmentForm({
      client: null,
      date: null,
      employee: null,
      services: [],
      totalPrice: null,
      totalDuration: null
    }));
  }

  trackEventOpenHomePage() {
    this.amplitudeService.trackEvent(
      'widget_new_appointment_clicked',
      {
        company_id: this.branch.company_id,
        branch_id: this.branch.id,
      });
  }

  trackEventAddToGoogleCalendar() {
    this.amplitudeService.trackEvent(
      'widget_add_to_calendar_clicked',
      {
        company_id: this.branch.company_id,
        branch_id: this.branch.id,
      });
  }

  trackEventOpenTelegramBot() {
    this.amplitudeService.trackEvent(
      'widget_telegram_bot_clicked',
      {
        company_id: this.branch.company_id,
        branch_id: this.branch.id,
      });
  }

}
