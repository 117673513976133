export default {
  forms: {
    errors: {
      required: '{{label}} є обов`язковим полем',
      email: 'Введіть коректний email',
      mask: 'Невірно введений номер'
    }
  },
  errors: {
    4010: {
      icon: 'alert',
      title: 'Вибачте, але ця дія для вас недоступна',
      description: 'Зверніться до адміністратора'
    },
    4030: {
      icon: 'calendar',
      title: 'Ой, запис на цей час неможливий',
      description: 'Ми працюємо лише в робочі години'
    },
    4031: {
      icon: 'calendar',
      title: 'Схоже, цей час уже заброньований',
      description: 'Оберіть інший, будь ласка!'
    },
    4041: {
      icon: 'calendar',
      title: 'На жаль, працівник не доступний у цей день',
      description: 'Давайте оберемо іншу дату?'
    },
    4043: {
      icon: 'services-warning',
      title: 'Ці послуги не активні або працівник не надає їх',
      description: ''
    },
    404: {
      icon: 'ghost',
      title: '404: Сторінку не знайдено',
      description: 'Сторінка видалена або ніколи не існувала.'
    },
    500: {
      icon: 'error',
      title: 'Щось пішло не так',
      description: 'Спробуйте інші способи для створення запису'
    }
  }
}
