<div class="search-input-container input-wrapper with-icon">
  <i class="icon-search main-icon"></i>
  <input
    type="text"
    class="search-input with-icon"
    [placeholder]="placeholder"
    [formControl]="control"
  />
  <button class="clear-button" *ngIf="control.value" (click)="clearSearch()">
    <i class="icon-delete"></i>
  </button>
</div>
