<mat-paginator
  #paginator
  [length]="itemsCount$ | async"
  [pageSize]="pageSize"
  [selectConfig]="{panelClass: 'paginator-custom-select-panel'}"
  [pageIndex]="currentPage"
  [pageSizeOptions]="[10, 15, 20]"
  (page)="onPageChange.emit($event)"
  [showFirstLastButtons]="true"
  *ngIf="(itemsCount$ | async) > pageSize"
></mat-paginator>
