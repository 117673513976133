import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { AmplitudeService } from '../../entities/amplitude.service'
import { TranslateService } from '@ngx-translate/core'
import { AppActions } from '../../state/app-state.action'
import { EmptyStateComponent } from '../empty-state/empty-state.component'

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    EmptyStateComponent,
  ],
})
export class ErrorPageComponent implements OnInit {
  @Input() companyId: string = null;
  @Input() branchId: string = null;
  @Input() errorCode: number = 500;
  @Output() onClickButton = new EventEmitter<void>();

  errorInfo = null;

  constructor(
    private router: Router,
    private store: Store,
    private amplitudeService: AmplitudeService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.trackEventOpenErrorPage()
    this.setErrorMessage()
    this.resetForm()
  }

  trackEventOpenErrorPage() {
    this.amplitudeService.trackEvent(
      'widget_error_shown',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
        error_text: "На жаль сталася помилка",
        location: this.router.url,
      });
  }

  setErrorMessage() {
    this.errorInfo = this.translateService.instant('errors.' + this.errorCode);
    if (!this.errorInfo) {
      this.errorInfo = this.translateService.instant('errors.500');
    }
  }

  resetForm() {
    this.store.dispatch(new AppActions.UpdateAppointmentForm({
      client: null,
      date: null,
      employee: null,
      services: [],
      totalPrice: null,
      totalDuration: null,
    }));
  }

}
