import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { ToastrService } from 'ngx-toastr';
import { LoadingSelectors } from '../../state/loading/loading.selectors'

@Component({
  template: ''
})
export abstract class FormBaseComponent implements OnInit, OnDestroy {
  @Select(LoadingSelectors.isLoading) isLoading$: Observable<boolean>;

  onDestroy$ = new Subject<void>();
  initialFormValue: any;
  formHasChange: boolean = false;
  form: FormGroup;
  addAction() {};
  redirectBack() {};

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.onFormValueChange();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit(event: Event) {
    event.preventDefault();

    if (this.form.valid) {
      return this.addInstance();
    }

    for (let props in this.form.controls) {
      this.form.controls[props].markAsDirty();
    }
  }

  addInstance(): void {}

  navigate(path: string) {
    this.router.navigateByUrl(path);
  }

  onFormValueChange() {
    this.initialFormValue = this.form.value;
    this.form.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(value => {
      this.formHasChange = !isEqual(value, this.initialFormValue);
    });
  }
}
