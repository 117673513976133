import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { DatePipe } from '@angular/common'
import { AddAppointment, Appointment, AppointmentFormModel } from '../../interfaces/appointment.interface'
import { AppointmentsApiService } from '../../api-service/appointments/appointments-api.service'
import { Path } from '../../app-routing.config'
import { AppStateSelectors } from '../../state/app-state.selector'
import { Branch } from '../../interfaces/branch.interface'
import { AmplitudeService } from '../../entities/amplitude.service'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  formValue: AppointmentFormModel;
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;
  hasError: boolean = false;
  errorCode: number = null;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private appointmentsApiService: AppointmentsApiService,
    private datePipe: DatePipe,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);
      if (!this.formValue.employee || !this.formValue.services || !this.formValue.date) {
        this.hasError = true;
        this.errorCode = 404;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  trackEventAppointmentCreate(appointment: Appointment) {
    this.amplitudeService.trackEvent(
      'widget_appointment_created',
      {
        company_id: appointment.company_id,
        branch_id: appointment.branch_id,
        appointment_id: appointment.id,
        client_id: appointment.client_id,
        service_ids: (appointment.services as any[]).map(service => service.id),
        services_sum: appointment.services_sum,
        employee_id: appointment.employee_id,
        appointment_date: appointment.start_at,
        appointment_price: appointment.price,
        appointment_duration: appointment.duration,
        comment: appointment.description,
      });
  }

  onSubmit(id: string){
    const arrayOfIds = this.formValue.services.map(service => service.id);
    const shortenedTime = this.formValue.date.timeSlot.split(":").slice(0, 2).join(":");
    const shortenedDate = this.datePipe.transform(this.formValue.date.date, 'yyyy-MM-dd');
    const date = new Date(`${shortenedDate} ${shortenedTime}`);
    const isoFormatDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    const body: AddAppointment = {
      branch_id: this.branchId,
      company_id: this.companyId,
      client_id: id,
      employee_id: this.formValue.employee.id,
      start_at: isoFormatDate,
      services: arrayOfIds,
      price: this.formValue.totalPrice,
      duration: this.formValue.totalDuration,
      description: this.formValue.description
    };

    this.appointmentsApiService.addAppointments(body).subscribe(
      {
        next: ( appointment ) => {
          this.trackEventAppointmentCreate(appointment);
          this.goToSuccessPage(appointment.id);
        },
        error: ({error}) => {
          this.hasError = true;
          this.errorCode = error.detail.error_code;
        }
      }
    )
  }

  goToHomePage() {
    this.router.navigate([this.branchSlug], {
      queryParamsHandling: 'merge',
    });
  }

  goToSuccessPage( appointmentId: string ) {
    this.router.navigate([this.branchSlug, Path.SUCCESS, appointmentId], {
      queryParamsHandling: 'merge',
    });
  }

}
