<form
  [formGroup]="form"
  (ngSubmit)="onSubmit($event)"
  #localForm="ngForm"
  class="form-container">
    <app-input
      label="Ім'я"
      formControlName="first_name"
    ></app-input>
    <app-input
      label="Прізвище"
      formControlName="last_name"
    ></app-input>
    <app-input
      type="tel"
      label="Телефон"
      formControlName="phone_number"
    ></app-input>
    <app-input
      type="email"
      label="Email"
      formControlName="email"
    ></app-input>

    <div class="submit-button-wrapper">
      <button type="submit" class="primary-dark" [disabled]="!formHasChange">
        Записатись
      </button>
    </div>
</form>
